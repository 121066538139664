'use client';

import type { Lang } from './_internal/i18n/settings';

import { useParams } from 'next/navigation';

import I18nextProvider from '@app/_internal/providers/i18n-provider';
import { NOOP_FN } from '@/constants';
import { NotFoundErrorPage } from '@/initializers/error-page/NotFoundErrorPage';

import { UiProvider } from './_internal/providers/ui-provider';

export default function Page() {
  const params = useParams<{ lang: Lang }>();

  return (
    <UiProvider>
      <I18nextProvider lang={params?.lang!}>
        <NotFoundErrorPage resetErrorBoundary={NOOP_FN} />
      </I18nextProvider>
    </UiProvider>
  );
}
